<template lang="pug">
include ../../../configs/template
div.container-fluid
  form.row
    div.col-12.col-sm-12.col-md-4
      +date-picker-validation('body.date_start','dateEffective', 'dateIssueObject', '["required", "maxValue", "minValue"]')(
        max="2200-12-31"
        :min="tomorrow"
      )
    div.col-12.col-sm-12.col-md-4
      +field-validation('body.percent_of_eti','etiPercent','["required", "minValue", "maxValue"]')(
        @input="countPercent('percent_of_profit','percent_of_eti')"
      )
    div.col-12.col-sm-12.col-md-4
      +field-validation('body.percent_of_profit','profitPercent','["required", "minValue", "maxValue"]')(
        @input="countPercent('percent_of_eti','percent_of_profit')"
      )
    div.col-12.col-sm-12
      div
        v-btn(color="success" :loading="isLoading" @click="saveCoefficientETI") {{ $t('save') }}

</template>

<script>
import { coefficientETI } from '@/configs/validations/index'
import { dateFormat } from '@/mixins/main'
import { mapActions } from 'vuex'
import { SUCCESS_CODE } from '../../../configs/constants'
const initBody = () => (
  {
    date_start: null,
    percent_of_eti: null,
    percent_of_profit: null
  }
)
export default {
  name: 'AddCoefficient',
  props: {
    coefficientById: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody(),
      isLoading: false
    }
  },
  computed: {
    dateIssueObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    tomorrow () {
      let tomorrow = new Date()
      tomorrow.setDate(new Date().getDate() + 1)
      return dateFormat(tomorrow)
    }
  },
  validations () { return coefficientETI(this) },
  mounted () {
    if (this.$route.params.documentID) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.coefficientById[key]))
    }
  },
  methods: {
    ...mapActions(['createCoefficient', 'updateCoefficientById']),
    async saveCoefficientETI () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true

      const response = this.$route.params.documentID
        ? await this.updateCoefficientById({ ...this.$route.params, body: this.body })
        : await this.createCoefficient({ body: this.body })

      if (SUCCESS_CODE.includes(response.code)) {
        this.$v.$reset()
        this.body = initBody()
        this.$parent.isViewAddSlot = false
      }
      this.isLoading = false
    },
    countPercent (keyFirst, keySecond) {
      this.body[keyFirst] = 100 - this.body[keySecond]
    }
  }
}
</script>
